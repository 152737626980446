import {useContext} from 'react'
import {Link} from 'react-router-dom'
import cx from 'classnames'
import {useTranslation} from 'react-i18next'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import DOMPurify from 'dompurify'
import {motion} from 'framer-motion'

import {authContent} from '~/providers/Auth'

import {BarsThree} from '../Icons/BarsThree'
import {SmartActions} from './components/SmartActions/SmartActions'
import {Button} from '../Button/Button'
import {setReadEmailsInCacheOnNavigate} from '../Inbox/components/LeftSidebar/utils'

import {emailCreatedAgo} from './utils'
import {useMarkAsRead} from './hooks/emailActions'
import {updateSubEmail} from '~/utilities/cache/subs'

type SubIconSize = 'regular' | 'medium' | 'large'

type SubIconProps = {
  src?: string
  className?: string
  size?: SubIconSize
}

export function SubIcon({src, className, size = 'regular'}: SubIconProps) {
  if (!src) return null

  const classNames = cx(
    'overflow-hidden rounded bg-white ring-2 ring-gray-200',
    {
      'w-6 h-6': size === 'regular',
      'w-8 h-8': size === 'medium',
      'w-10 h-10': size === 'large',
    },
    className,
  )

  return <img src={src} className={classNames} />
}

type EmailProps = Email & {
  showSender?: boolean
  showIcon?: boolean
  showTopics?: boolean
  showMarkAsRead?: boolean
  className?: string
  onMarkAsRead?: () => void
}

type EmailHeaderProps = EmailProps & {
  sticky?: boolean
}

export function EmailHeader({
  showSender,
  showIcon,
  showTopics = true,
  sticky,
  showMarkAsRead = true,
  onMarkAsRead,
  ...email
}: EmailHeaderProps) {
  const {t} = useTranslation('topics')

  const {sender, subject} = email

  return (
    <div
      className={cx(
        'w-full flex items-center p-4 pb-2.5 gap-2 bg-white rounded-t-xl overflow-hidden shadow-solid-top-surface-l',
        {
          'sticky top-0': sticky,
          relative: !sticky,
        },
      )}
    >
      <div className="whitespace-nowrap flex-grow min-w-0 pr-6">
        {(showSender || showIcon) && (
          <div className="relative flex items-center gap-2 mb-2">
            {sender.logo && showIcon && (
              <SubIcon src={sender.logo} className="ring-2 ring-gray-200" />
            )}
            {showSender && (
              <Link to={`/subs/${sender.slug}`}>
                <h3 className="text-sm font-semibold">{sender.name}</h3>
              </Link>
            )}
          </div>
        )}
        <h4 className="w-full text-xl font-bold text-ellipsis overflow-hidden">
          {subject}
        </h4>
      </div>
      <div className="ml-auto flex gap-2 items-center">
        <time
          className="text-text-subdued text-xs font-medium whitespace-nowrap"
          dateTime="<%= email.created_at %>"
        >
          {emailCreatedAgo(email)}
        </time>
        {showTopics && (
          <>
            <span className="text-xs text-gray-400">•</span>
            {sender.topics.map((topic) => (
              <Link
                key={topic.id}
                to={`/topics/${topic.name}`}
                className="text-xs font-medium underline underline-offset-2 whitespace-nowrap"
              >
                {t(`${topic.name}.title`)}
              </Link>
            ))}
          </>
        )}
        {showMarkAsRead && !email.read && (
          <>
            <span className="text-xs text-gray-400">•</span>
            <Button
              type="button"
              size="sm"
              variant="plain"
              onClick={onMarkAsRead}
            >
              Mark as read
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export function Email({
  showIcon = true,
  showSender = true,
  showMarkAsRead = true,
  ...email
}: EmailProps) {
  const {currentUser} = useContext(authContent)
  const {id, html, blurb, className} = email
  const {mutate: markAsRead} = useMarkAsRead({
    email,
    onMutateCallback: () => {
      updateSubEmail(email.sender.slug, {...email, read: true})
      setTimeout(() => setReadEmailsInCacheOnNavigate([`${email.id}`], []), 150)
    },
  })

  return (
    <motion.article
      className={cx(
        'relative w-full flex flex-col items-start bg-white rounded-xl overflow-hidden transition-opacity border border-gray-300',
        className,
      )}
      initial={{opacity: 1, height: 'auto'}}
      animate={{height: 'auto', opacity: 1}}
      exit={{height: 0, opacity: 0}}
      transition={{type: 'tween', duration: 0.15, ease: 'circInOut'}}
    >
      <EmailHeader
        showIcon={showIcon}
        showSender={showSender}
        showMarkAsRead={showMarkAsRead}
        onMarkAsRead={markAsRead}
        {...email}
      />
      <Link
        to={`emails/${id}`}
        className="w-full cursor-pointer relative bg-white"
        state={{email}}
      >
        <span className="block w-full h-0.5 bg-gradient-to-r from-[#d2d2d2] to-[#EEEEEE]"></span>
        <div className="bg-white rounded-b-lg overflow-hidden w-full pointer-events-none">
          {currentUser!.settings.view === 'previews' && (
            <div className="h-[380px]">
              {email.screenshot ? (
                <LazyLoadImage
                  alt={`Screenshot of email about ${email.subject}`}
                  width={2048}
                  height={380}
                  src={email.screenshot}
                  className="block w-full overflow-hidden object-cover object-top"
                />
              ) : (
                <iframe
                  srcDoc={`<style>body{margin:0;}</style>${DOMPurify.sanitize(html)}`}
                  className="w-full h-[380px] overflow-hidden invisible"
                  sandbox="allow-scripts"
                  onLoad={(event) =>
                    (event.target as HTMLIFrameElement).classList.remove(
                      'invisible',
                    )
                  }
                />
              )}
            </div>
          )}
          {currentUser!.settings.view === 'blurbs' && (
            <p className="w-full p-4 font-medium leading-relaxed text-gray-800">
              <BarsThree
                className="inline-block mr-1.5 text-gray-400"
                strokeWidth={2}
              />
              {blurb}
            </p>
          )}
        </div>
      </Link>
      {email?.smartActions.length !== 0 && (
        <SmartActions
          smartActions={email.smartActions}
          floats={currentUser!.settings.view === 'previews'}
        />
      )}
    </motion.article>
  )
}
