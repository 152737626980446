import {useEffect, useRef} from 'react'
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react'
import DOMPurify from 'dompurify'

import {fetcher, queryClient} from '~/utilities/queries'
import {useFeedContext} from '~/routes/Dashboard/Dashboard'

import {EmailHeader} from './Email'
import {useEmailFetcher, type GroupedEmails} from './hooks/useEmails'
import {updateHomeFeedCache} from './cache'

export async function getEmail(id: string): Promise<{email: Email}> {
  try {
    const emailData = await fetcher(`/api/emails/${id}`)

    const homeEmails = queryClient.getQueryData<GroupedEmails>(['homeEmails'])
    const existingEmail =
      homeEmails?.[emailData.email.sender.slug]?.[emailData.email.id]

    if (!existingEmail) {
      queryClient.setQueryData(
        ['homeEmails'],
        (oldData: GroupedEmails | undefined) =>
          updateHomeFeedCache(emailData.email, oldData),
      )
    }

    return emailData
  } catch (error) {
    throw error
  }
}

function Wrapper({children}: {children: React.ReactNode}) {
  return <div className="relative h-full overflow-hidden">{children}</div>
}

export function EmailPreview() {
  const {data, isLoading, error, failureCount} = useEmailFetcher({
    skipQuery: true,
  })

  const previewIframe = useRef<HTMLIFrameElement | null>(null)
  const scrollbarContainer = useRef<HTMLDivElement | null>(null)
  const [_, setFeedContext] = useFeedContext()

  useEffect(() => {
    setFeedContext((value) => ({
      ...value,
      enableCustomScrollbar: false,
    }))

    return () =>
      setFeedContext((value) => ({
        ...value,
        enableCustomScrollbar: true,
      }))
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (previewIframe.current !== null) {
        previewIframe.current.style.height = `${previewIframe.current?.contentDocument?.body.scrollHeight}px`
      }
    }, 200)
  }, [data])

  if (isLoading)
    return (
      <Wrapper>
        <div className="h-full bg-white flex items-center justify-center rounded-xl">
          <div className="text-gray-500">
            {failureCount > 2
              ? "We're having trouble loading this email. Trying again..."
              : 'Loading...'}
          </div>
        </div>
      </Wrapper>
    )

  if (error && error?.message.includes('404'))
    return (
      <Wrapper>
        <div className="h-full bg-white flex items-center justify-center rounded-xl">
          <div className="text-gray-500">Email not found</div>
        </div>
      </Wrapper>
    )

  if (!data) return null

  const {email} = data

  return (
    <Wrapper>
      <div ref={scrollbarContainer} />
      <OverlayScrollbarsComponent
        defer
        options={{
          scrollbars: {autoHide: 'scroll', theme: 'email-preview-scrollbar'},
        }}
        events={{
          updated: ({elements}) => {
            const {content} = elements()

            content.scrollTo({
              top: 0,
            })
          },
          initialized: ({elements}) => {
            const {scrollbarVertical} = elements()

            const container = document.getElementById('scrollbar-container')
            container?.append(scrollbarVertical.scrollbar)
          },
        }}
        className="h-full overflow-hidden rounded-xl border border-gray-300"
      >
        <EmailHeader
          sticky
          showTopics={false}
          showMarkAsRead={false}
          {...email}
        />
        <span className="sticky top-[54px] block w-full h-0.5 bg-gradient-to-r from-[#A3A3A3] to-[#EEEEEE]" />
        <iframe
          srcDoc={`<base target='_blank' /><style>body{margin:0;}</style>${DOMPurify.sanitize(email.html)}`}
          className="w-full h-full bg-white"
          scrolling="no"
          ref={previewIframe}
        />
      </OverlayScrollbarsComponent>
    </Wrapper>
  )
}
