import {formatDistance} from 'date-fns'

export function emailCreatedAgo(email: Email) {
  return formatDistance(new Date(email.createdAt), new Date(), {
    addSuffix: true,
  })
}

export function emailPath(prefix: string, email: Email) {
  return prefix === '' ? `/emails/${email.id}` : `${prefix}/emails/${email.id}`
}

export function getURLHostname(url: string) {
  try {
    return new URL(url).hostname
  } catch (error) {
    return url
  }
}
