import {RouterProvider} from 'react-router-dom'
import {QueryClientProvider} from '@tanstack/react-query'
import {I18nextProvider} from 'react-i18next'
import cx from 'classnames'

import {DesktopAppTitleBar} from './components/DesktopAppTitleBar/DesktopAppTitleBar'

import {router} from './routes'
import {queryClient} from './utilities/queries'
import {useIsDesktopClient} from './hooks/useIsDesktopClient'

import {i18n} from './i18n'

export function App() {
  const isDesktopClient = useIsDesktopClient()

  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <main
          className={cx('h-screen w-full grid grid-cols-1', {
            'grid-rows-[min-content_1fr]': isDesktopClient,
          })}
        >
          <DesktopAppTitleBar />
          <div
            className={cx(
              'max-w-[1680px] w-full h-full mx-auto overflow-hidden',
              {
                'p-4': isDesktopClient,
                'p-10': !isDesktopClient,
              },
            )}
          >
            <RouterProvider router={router} />
          </div>
        </main>
      </QueryClientProvider>
    </I18nextProvider>
  )
}
