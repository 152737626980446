import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {useMemo} from 'react'
import {useParams} from 'react-router-dom'

import {useAuthContext} from '~/providers/Auth'

import {getEmail} from '../EmailPreview'

export type GroupedEmails = {[key: string]: Email[]}

export function flattenGroupedEmails(groupedEmails: GroupedEmails) {
  return Object.values(groupedEmails).reduce<Email[]>((acc, email) => {
    acc.push(...email)

    return acc
  }, [])
}

export function filterEmails(emails: Email[] = [], feedView: FeedViewSetting) {
  if (!emails || emails?.length === 0) return []

  return emails.filter((email) => {
    if (feedView === 'unread') {
      return !email.read
    } else if (feedView === 'read') {
      return email.read
    }

    return true
  })
}

export function filterFeedEmails(
  feedView: FeedViewSetting,
  groupedEmails?: GroupedEmails,
) {
  if (!groupedEmails) return {}

  return Object.keys(groupedEmails).reduce((acc, key) => {
    const emailGroup = filterEmails(groupedEmails[key], feedView)

    if (emailGroup?.length) {
      acc[key] = emailGroup
    }

    return acc
  }, {} as GroupedEmails)
}

export function useEmails() {
  const {currentUser} = useAuthContext()
  const params = useParams<{slug?: string; topicName?: string}>()
  const {data: subData} = params.slug
    ? useQuery<Sub>({queryKey: ['sub', params.slug]})
    : {data: null}
  const {data: topic} = params.topicName
    ? useQuery<{emails: GroupedEmails}>({queryKey: ['topic', params.topicName]})
    : {data: null}
  const {data: homeEmails} = useQuery<GroupedEmails>({queryKey: ['homeEmails']})

  const emails: Email[] = useMemo(() => {
    if (subData) return subData.emails
    if (topic) {
      return flattenGroupedEmails(topic.emails)
    }
    if (homeEmails) {
      return flattenGroupedEmails(
        filterFeedEmails(currentUser!.settings.feedView, homeEmails),
      )
    }

    return []
  }, [subData, topic, homeEmails])

  return emails
}

type EmailFetcherOptions = {
  skipQuery?: boolean
  refetchOnWindowFocus?: boolean
} & Omit<UseQueryOptions<{email: Email}>, 'queryKey' | 'queryFn' | 'enabled'>

export function useEmailFetcher({
  skipQuery,
  ...options
}: EmailFetcherOptions = {}) {
  const params = useParams<{emailId: string}>()

  return useQuery<{email: Email}>({
    queryKey: ['email', params.emailId],
    queryFn: async () => await getEmail(params.emailId!),
    enabled: !skipQuery,
    ...options,
  })
}
